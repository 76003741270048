@use "./styles/variables";

// ---- FONTS ----

@font-face {
	font-family: "Poppins Regular";
	font-style: normal;
	font-weight: normal;
	font-display: swap;
	src: local('Poppins Regular'), url('./fonts/Poppins-Regular.ttf') format('woff');
}

@font-face {
	font-family: "WorkSans Regular";
	font-style: normal;
	font-weight: normal;
	font-display: swap;
	src: local('WorkSans Regular'), url('./fonts/WorkSans-Regular.ttf') format('woff');
}

// ---- VARIABLES ----

:root {
	--mobile-breakpoint-gigantic: #{variables.$mobile-breakpoint-gigantic};
	--mobile-breakpoint-very-big: #{variables.$mobile-breakpoint-very-big};
	--mobile-breakpoint-big: #{variables.$mobile-breakpoint-big};
	--mobile-breakpoint-medium: #{variables.$mobile-breakpoint-medium};
	--mobile-breakpoint-small: #{variables.$mobile-breakpoint-small};
	--mobile-breakpoint-very-small: #{variables.$mobile-breakpoint-very-small};

	--side-padding-big: 300px;
	--side-padding-medium: 25px;
	--side-padding-small: 15px;
	
	--top-margin: 100px;
	--bottom-margin: 160px;
	--header-height: 80px;
	--footer-height: 260px;
	--section-gap: 250px;
	--scroll-margin: 100px;

	--text-color: #000000;
	--background-color: rgb(0 0 0 / 44%);
	--box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);

	--primary-color: #068FFF;
	--background-color: #000000;

	--transition-duration-long: 400ms;
	--transition-duration-short: 200ms;

	--text-font: WorkSans Regular;
	--title-font: Poppins Regular;
}

// ---- GLOBAL ELEMENTS ----

html {
    height: 100%;
}

body {
	font-family: var(--text-font);
	color: var(--text-color);
	background-color: #e2e2e2d8;
	-webkit-tap-highlight-color: transparent;
}

h1, h2, button, nav > * {
	font-family: var(--title-font);
}

hr {
	border: none;
	border-top: 1px solid var(--primary-color);
	width: 100%;
}

* {
	font-family: inherit;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

// ---- INPUTS ----

input, textarea, button {
	background-color: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

// ---- SCROLLBAR ----

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background-color: var(--background-color);
}

::-webkit-scrollbar-thumb {
	background-color: var(--primary-color);

	&:hover {
		background-color: var(--primary-color);
	}
}

@use "./animations";
@use "./variables";

@mixin box {
	border-radius: 10px;
	box-shadow: var(--box-shadow);
	padding: 30px;
	background: var(--background-color);

	@media screen and (max-width: variables.$mobile-breakpoint-small) {
		padding: 15px;
	}
}

@mixin middle {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin side_padding {
	padding-left: var(--side-padding-big);
	padding-right: var(--side-padding-big);
	
	@media screen and (max-width: variables.$mobile-breakpoint-very-big) {
		padding-left: var(--side-padding-medium);
		padding-right: var(--side-padding-medium);
	}

	@media screen and (max-width: variables.$mobile-breakpoint-small) {
		padding-left: var(--side-padding-small);
		padding-right: var(--side-padding-small);
	}
}

@mixin button {
	cursor: pointer;
	color: #ffffff;
	background-color: var(--primary-color);
	border: 2px solid var(--primary-color);
	font-family: var(--title-font);
	text-decoration: none;
	border-radius: 5px;
	padding: 10px 20px;

	transition: all var(--transition-duration-long) ease;
	transition-property: background-color;

	&:hover {
		transition: all var(--transition-duration-long) ease;
		transition-property: background-color;

		background-color: var(--background-color);
	}
}

@mixin medium_width {
	@media screen and (max-width: variables.$mobile-breakpoint-medium) {
		width: 400px;
	}

	@media screen and (max-width: variables.$mobile-breakpoint-very-small) {
		width: 100%;
		max-width: none;
	}
}

@mixin border_color {
	display: flex;
	flex-direction: column;
	position: relative;
	align-self: center;

	@media screen and (max-width: variables.$mobile-breakpoint-medium) {
		width: 100%;
	}

	&:before, &:after {
		content: '';
		position: absolute;
		left: -2px;
		top: -2px;
		background: linear-gradient(45deg, #4329ec, #e2c15d);
		background-size: 400%;;
		width: calc(100% + 4px);
		height: calc(100% + 4px);
		z-index: -1;
		border-radius: 30px;
		animation: border_color_fade 20s linear infinite;
	}
	
	&:after {
		filter: blur(50px);
	}
}
@keyframes fade {
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes fade_almost {
	from { opacity: 0; }
	to { opacity: 0.8; }
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

@keyframes pulse {
    0% { transform: translateY(0px);  opacity: 1;}
    25% { transform: translateY(2px); opacity: 0.5;}
    50% { transform: translateY(0px); opacity: 0.8;}
    75% { transform: translateY(-2px); opacity: 1;}
    100% { transform: translateY(0px); opacity: 1;}
}

@keyframes border_color_fade {
	0% { background-position: 0 0; }
	50% { background-position: 400% 0; }
	100% { background-position: 0 0; }
}
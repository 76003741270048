@use "../../../styles/animations";

.background_invisible {
	display: none;
}

.background {
	position: fixed;
	background: var(--background-color);
	width: 100%;
	height: 100%;
	z-index: 2000;
	top: 0;
	left: 0;
	cursor: pointer;
	animation: fade 0.3s ease;
}

@use "../../../styles/mixins";
@use "../../../styles/variables";

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
	margin-top: var(--header-height); // sticky
	margin-bottom: var(--bottom-margin);
}

.container {
	display: flex;
	flex-direction: column;

	width: 100%;
	min-height: calc(100vh - (var(--header-height) + var(--footer-height) + var(--bottom-margin)));
}
.link {
	transition: all var(--transition-duration-long) ease;
	transition-property: color, background-color, text-decoration;

	&:link, &:visited {
		color: var(--primary-color);
		background-color: transparent;
		text-decoration: none;
	}

	&:hover, &:active {
		color: var(--primary-color);
		background-color: transparent;
		text-decoration: underline;
	}
}
@use "../../../styles/variables";

.container {
	align-self: center;
}

.logo {
	text-decoration: none;
	color: #ffffff;
	border: none;
	border-radius: 10px;
	cursor: pointer;
	user-select: none;
	-webkit-tap-highlight-color: #00000000;

	display: flex;
	flex-direction: row;
	gap: 10px;

	transition: all var(--transition-duration-long) ease;
	transition-property: opacity;

	&:hover {
		opacity: 0.8;

		transition: all var(--transition-duration-long) ease;
		transition-property: opacity;
	}
}

.logo_image {
	width: 50px;
	height: 50px;
	align-self: center;
}

.logo_text {
	font-size: 25px;
	align-self: center;
	font-weight: bold;
}
@use "../../../styles/animations";
@use "../../../styles/mixins";
@use "../../../styles/variables";

.container {
	@include mixins.side_padding;

	display: flex;
	flex-direction: row;
	justify-content: space-between;

    position: fixed;
	bottom: 0px;
	left: 0px;
    width: 100%;
	z-index: 999;
	background: var(--background-color);
	border-top: 2px solid var(--primary-color);
	padding-top: 20px;
	padding-bottom: 20px;
	animation: fade 0.5s ease;

	@media screen and (max-width: variables.$mobile-breakpoint-medium) {
		flex-direction: column;
		gap: 40px;
	}
}

.text {
	display: flex;
	flex-direction: column;
	gap: 3px;
	align-items: flex-start;
	color: #ffffff;

	@media screen and (max-width: variables.$mobile-breakpoint-small) {
		gap: 20px;
	}
}

.button_wrapper {
	display: flex;
	flex-direction: row;

	@media screen and (max-width: variables.$mobile-breakpoint-medium) {
		flex-direction: column;
	}
}

.button {
	@include mixins.button;

	font-size: 18px;
    padding: 10px 30px;
}

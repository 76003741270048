@use "../../../styles/animations";
@use "../../../styles/mixins";
@use "../../../styles/variables";

// ---- HEADER ----

.header {
	@include mixins.side_padding;

	background-color: var(--background-color);
	border-bottom: 2px solid var(--primary-color);
    overflow: hidden;
	padding-top: 10px;
	padding-bottom: 10px;
	height: var(--header-height);

	position: fixed; // sticky
	top: 0; // sticky
	z-index: 100; // sticky
	width: 100%; // sticky

	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

// ---- NAV ----

.nav_desktop {
	display: flex;
	flex-direction: row;
	gap: 50px;
	align-self: center;
    align-items: center; // sticky
}

.nav_mobile {
	@include mixins.middle;

	flex-direction: column;
	gap: 40px;
	align-items: center;
	display: none;
	z-index: 2100;
	border-radius: 10px;
	animation: fade 0.5s ease;
}

.nav_mobile_visible {
	display: flex;
}

// ---- LINK ----

.link {
	color: #ffffff;
    text-decoration: none;
	font-size: 18px;
	border: none;
	cursor: pointer;
}

.link_hover {
	display: inline-block;
	position: relative;
}

.link_hover::after {
	content: '';
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 2px;
	bottom: 0;
	left: 0;
	background-color: var(--primary-color);
	transform-origin: bottom right;
	transition: transform 0.25s ease-out;
}

.link_hover:hover::after {
	transform: scaleX(1);
	transform-origin: bottom left;
}

.link_desktop {
	display: inline-block;
	list-style-type: none;
	padding: 3px 0;
	border-radius: 10px;
}

.link_mobile {
	font-size: 30px;
	width: fit-content;
}

.contact_button {
	@include mixins.button;

	padding: 8px 16px;

	@media screen and (max-width: variables.$mobile-breakpoint-very-big) {
		width: 202px;
    	text-align: center;
	}
}

// ---- MENU ----

.menu_button_container {
	width: 50px;

	display: flex;
	flex-direction: row;
	justify-content: right;
}

.menu_button {
	display: flex;
	height: 100%;
	width: 50px;
	cursor: pointer;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 10000;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: #00000000;
	margin-right: -10px;
}

.menu_checkbox {
	display: none;
}

.menu_button_part,
.menu_button_part::before,
.menu_button_part::after {
	display: block;
	background-color: #ffffff;
	position: absolute;
	height: 4px;
	width: 30px;
	transition: transform var(--transition-duration-long) cubic-bezier(0.23, 1, 0.32, 1);
	border-radius: 2px;
}

.menu_button_part::before {
	content: "";
	margin-top: -8px;
}

.menu_button_part::after {
	content: "";
	margin-top: 8px;
}

.menu_checkbox:checked + .menu_button .menu_button_part::before {
	margin-top: 0px;
	transform: rotate(405deg);
}

.menu_checkbox:checked + .menu_button .menu_button_part {
	background: rgba(255, 255, 255, 0);
}

.menu_checkbox:checked + .menu_button .menu_button_part::after {
	margin-top: 0px;
	transform: rotate(-405deg);
}
@use "../../../styles/mixins";
@use "../../../styles/variables";

.footer {
	@include mixins.side_padding;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 50px;
	padding-top: 50px;
	padding-bottom: 30px;
	background-color: var(--background-color);
	border-top: 2px solid var(--primary-color);
	min-height: var(--footer-height);
	color: #ffffff;
	margin-top: auto;
}

.row_1 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	@media screen and (max-width: variables.$mobile-breakpoint-small) {
		flex-direction: column;
		justify-content: flex-start;
		gap: 50px;
	}
}

.social_container {
	display: flex;
	flex-direction: row;
	gap: 20px;

	background-color: var(--primary-color);
	border-radius: 10px;
	padding: 8px 20px;

	@media screen and (max-width: variables.$mobile-breakpoint-very-small) {
		width: 100%;
		justify-content: center;
	}
}

.social_icon_container {
	display: flex;
	flex-direction: column;

	padding: 8px;
    background-color: #ffffff;
    border-radius: 20px;

	transition: all var(--transition-duration-long) ease;
	transition-property: opacity;

	&:hover {
		opacity: 0.5;
	}
}

.social_icon {
	width: 20px;
	height: 20px;
	filter: invert(0%) sepia(55%) saturate(253%) hue-rotate(339deg) brightness(100%) contrast(103%);
}

.row_2 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media screen and (max-width: variables.$mobile-breakpoint-medium) {
		flex-direction: column-reverse;
		gap: 40px;
		align-items: center;
	}
}

.copy_right {
	display: flex;
	flex-direction: row;
	gap: 5px;

	@media screen and (max-width: variables.$mobile-breakpoint-very-small) {
		font-size: 14px;
	}
}

.legal_container {
	display: flex;
	flex-direction: row;
	gap: 15px;

	@media screen and (max-width: variables.$mobile-breakpoint-very-small) {
		font-size: 14px;
		gap: 10px;
	}
}

.legal_link {
	color: #ffffff;
	text-decoration: none;

	transition: all var(--transition-duration-long) ease;
	transition-property: opacity;

	&:hover {
		opacity: 0.5;
	}
}